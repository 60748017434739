<template>
  <div class="login">
    <!-- <img style="height:100%;width:100%" src="../assets/img/ads43.png" alt=""> -->
    <div class="adsads" @click="tohomes">

    </div>
    <div class="login_center">
      <div class="login_card" v-if="!filg">
        <div :class="isforms ? 'erma' : 'actverma'" @click="gosaoma">
          <div class="lefterma" v-if="isforms">
            扫码登录
            <div class="sanjiao"></div>
          </div>
          <div class="lefterma" v-if="!isforms">
            <div class="sanjiao"></div>
            账号登录
          </div>
        </div>
        <div class="card_top" v-if="isforms">
          <div
            :class="active == 0 ? 'password colores' : 'password'"
            @click="tompassword(0)"
          >
            密码登录
          </div>
          <div
            :class="active == 1 ? 'password colores' : 'password'"
            class="twos"
            @click="tompassword(1)"
          >
            短信登录
          </div>
        </div>
        <div class="card_top" v-if="!isforms">
          <div class="colorsad">微信扫码，安全登录</div>
        </div>
        <div class="card_center" v-if="isforms">
          <el-input placeholder="请输入手机号" v-model="phones" clearable>
            <template slot="prepend">
              <img src="../assets/img/hsouji.png" />
            </template>
          </el-input>
          <div style="height: 26px"></div>
          <el-input
            v-if="active == 0"
            placeholder="请输入密码"
            type="password"
            v-model="password"
            show-password
          >
            <template slot="prepend"
              ><img src="../assets/img/mima.png"
            /></template>
          </el-input>
          <el-input
            v-if="active == 1"
            placeholder="请输入验证码"
            v-model="code"
            clearable
          >
            <template slot="prepend">
              <img src="@/assets/img/yaznhegma.png" />
            </template>
            <template slot="append">
              <el-button
                type="primary"
                size="small"
                :disabled="desides"
                @click="enxttop"
                >{{ textsnum }}</el-button
              ></template
            >
          </el-input>

          <div class="wangji" v-if="active != 1" @click="forget">
            忘记密码？
          </div>
          <div class="loginbtns" @click="formLogin">立即登录</div>
          <div class="weixindenglu">
            <img class="weixinerweima" @click="gosaoma" src="@/assets/img/weixings.png" alt="" />
          </div>
          <div class="zuceto" @click="toregists">还没有账号？立即注册</div>
        </div>
        <div class="card_center" v-if="!isforms">
          <div class="wxerwima">
            <wxlogin
              appid="wx018bc7dde37e6600"
              :scope="'snsapi_login'"
              :theme="'black'"
              :redirect_uri="
                encodeURIComponent(
                  'https://opalescence.cn/buyer/connect/callback/WECHAT_PC'
                )
              "
              :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7CiBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC53cnBfY29kZXsKd2lkdGg6IDE4MHB4OwpoZWlnaHQ6IDE4MHB4Owp9Ci5pbXBvd2VyQm94IC5xcmNvZGUgewogYm9yZGVyOiBub25lOwptYXJnaW4tdG9wOjA7CiB3aWR0aDogMTgwcHg7CiBoZWlnaHQ6IDE4MHB4Owp9Ci5pbXBvd2VyQm94IC5zdGF0dXN7CiBkaXNwbGF5OiBub25lCn0KLmltcG93ZXJCb3ggLmljb24zOF9tc2d7CiAgd2lkdGg6IDMwcHg7CiAgaGVpZ2h0OiAzMHB4Cn0KLmltcG93ZXJCb3ggLnN0YXR1c3sKcGFkZGluZzo3cHggMDsKfQo='"
              rel="external nofollow"
            >
            </wxlogin>
          </div>
          <!-- <div class="wxmess">打开微信扫描上方二维码</div> -->
          <div class="zuceto" style="margin-top: 55px" @click="toregists">
            还没有账号？立即注册
          </div>
        </div>
      </div>
      <div v-if="filg" class="login_cards">
        <div class="login_top">忘记密码</div>
        <div class="card_centers">
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item prop="mobile">
              <el-input
                placeholder="请输入手机号"
                v-model="form.mobile"
                clearable
              >
                <template slot="prepend">
                  <img src="../assets/img/hsouji.png" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-input
                placeholder="请输入验证码"
                v-model="form.code"
                clearable
              >
                <template slot="prepend">
                  <img src="@/assets/img/yaznhegma.png" />
                </template>
                <template slot="append">
                  <el-button
                    type="primary"
                    size="small"
                    :disabled="desidesa"
                    @click="enxttops"
                    >{{ textsnums }}
                  </el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                placeholder="设置新密码"
                type="password"
                v-model="form.password"
                show-password
              >
                <template slot="prepend"
                  ><img src="../assets/img/mima.png"
                /></template>
              </el-input>
            </el-form-item>
            <el-form-item prop="newpassword">
              <el-input
                placeholder="确认新密码"
                type="password"
                v-model="form.newpassword"
                show-password
              >
                <template slot="prepend"
                  ><img src="../assets/img/mima.png"
                /></template>
              </el-input>
            </el-form-item>
          </el-form>
          <div class="wangjis" @click="forgets">密码登录</div>
          <div class="loginbtnsa" @click="Submant('form')">确认</div>
        </div>
      </div>
    </div>
    <img class="imgfliexs" src="../assets/img/listadsfooter.png" @click="towaibulianjie" alt="">
  </div>
</template>

<script>
import {
  membersuserLogin,
  getPhoneCode,
  phoneLogin,
  connectlogin,
  connectresult,
  forgetpass,
} from "../request/homeApi";
import wxlogin from "vue-wxlogin";
export default {
  data() {
    let mobis = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        const mobile =
          /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/;
        if (!mobile.test(value)) {
          callback(new Error("请输入正确手机号码"));
          return false;
        }
        callback();
      }
    };
    let newpass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else {
        if (value !== this.form.password) {
          callback(new Error("两次密码不一致"));
          return false;
        }
        callback();
      }
    };
    let passwod = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        let regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
        if (!regex.test(value)) {
          callback(new Error("密码必须包含字母和数字,且在6~12位之间"));
          return false;
        }
        callback();
      }
    };
    return {
      imgcodeurl: "",
      phones: "",
      password: "",
      active: 0,
      code: "",
      textsnum: "获取验证码",
      isforms: true,
      desides: false,
      numbers: 60,
      filg: false,
      form: {
        mobile: "",
        code: "",
        newpassword: "",
        password: "",
      },
      rules: {
        mobile: [{ required: true, validator: mobis, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        newpassword: [{ required: true, validator: newpass, trigger: "blur" }],
        password: [{ required: true, validator: passwod, trigger: "blur" }],
      },
      textsnums: "获取验证码",
      desidesa: false,
      numbersa: 60,
    };
  },
  components: { wxlogin },
  created() {
    // this.GetRequest()
    let objs = this.GetRequest();
    if (objs.state == "NoBind") {
      this.$router.push({
        path: "/register?uid=" + objs.uid + "&unid=" + objs.unid,
      });
    }
    if (objs.state == "Bind") {
      let params = {
        uid: objs.uid,
      };
      connectresult(params)
        .then((res) => {
          localStorage.setItem("token", res.result.token.accessToken);
          localStorage.setItem("refreshToken", res.result.token.refreshToken);
          this.$router.push({ path: "/" });
          this.$message.success("登录成功");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.connectloginApi();
  },
  methods: {
    tohomes(){
      this.$router.push({ path: "/" });
    },
    towaibulianjie(){
				 window.open("https://www.ultradent.cn/", "_blank");
			},
    GetRequest() {
      var urlAll = location.href;
      var result = {};
      var querys = urlAll.substring(urlAll.indexOf("?") + 1).split("&");
      for (var i = 0; i < querys.length; i++) {
        var temp = querys[i].split("=");
        if (temp.length < 2) {
          result[temp[0]] = "";
        } else {
          result[temp[0]] = temp[1];
        }
      }
      return result;
    },
    connectloginApi() {
      connectlogin()
        .then((res) => {
          this.imgcodeurl = res.result;
          // debugger
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTime() {
      this.timer = setInterval(() => {
        this.numbers--;
        this.textsnum = "剩余" + this.numbers + "s";
        if (this.numbers == 0) {
          this.textsnum = "获取验证码";
          this.desides = false;
          this.numbers = 60;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    enxttop() {
      const mobile =
        /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/;
      if (!mobile.test(this.phones)) {
        this.$message.error("请输入正确手机号码");
        return;
      }
      let param = {
        mobile: this.phones,
        verificationEnums: "LOGIN",
        uuid: "asdf",
      };
      getPhoneCode(param)
        .then((res) => {
          if (res.code) {
            this.$message.success("获取验证码成功");
            this.desides = true;
            this.textsnum = "剩余" + this.numbers + "s";
            this.getTime();
          }
        })
        .catch((res) => {
          console.log("网络开小差了，没有获取到图片验证码哦");
        });
    },
    formLogin() {
      // 账号密码登录
      if (this.active == 0) {
        if (this.password == "" || this.phones == "") {
          this.$message.error("请输入完整账户和密码");
          return;
        }
        let data = {
          password: this.$md5(this.password),
          username: this.phones,
        };
        membersuserLogin(data)
          .then((res) => {
            console.log(res);
            if (res.code == 200) {
              localStorage.setItem("token", res.result.accessToken);
              localStorage.setItem("refreshToken", res.result.refreshToken);
              this.$router.push({ path: "/" });
              this.$message.success("登录成功");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // 手机短信登录
      if (this.active == 1) {
        if (this.code == "") {
          this.$message.error("请输入验证码");
          return;
        }
        let data = {
          code: this.code,
          mobile: this.phones,
        };
        phoneLogin(data)
          .then((res) => {
            console.log(res);
            if (res.code == 200) {
              localStorage.setItem("token", res.result.accessToken);
              localStorage.setItem("refreshToken", res.result.refreshToken);
              this.$router.push({ path: "/" });
              this.$message.success("登录成功");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    tompassword(index) {
      this.active = index;
      this.phones = "";
      this.password = "";
      this.code = "";
    },
    gosaoma() {
      this.isforms = !this.isforms;
    },
    toregists() {
      this.$router.push({ path: "/register" });
    },
    //忘记密码
    forget() {
      this.filg = true;
      this.form = {
        mobile: "",
        code: "",
        newpassword: "",
        password: "",
      };
    },
    //密码登陆
    forgets() {
      this.filg = false;
    },
    //忘记密码，获取验证码
    enxttops() {
      const mobile =
        /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/;
      if (!mobile.test(this.form.mobile)) {
        this.$message.error("请输入正确手机号码");
        return false;
      }
      let param = {
        mobile: this.form.mobile,
        verificationEnums: "FIND_USER",
        uuid: "asdf",
      };
      getPhoneCode(param).then((res) => {
        if (res.code) {
          this.$message.success("获取验证码成功");
          this.desidesa = true;
          this.textsnumas = "剩余" + this.numbersa + "s";
          this.getTimes();
        }
      });
    },
    getTimes() {
      this.timers = setInterval(() => {
        this.numbersa--;
        this.textsnums = "剩余" + this.numbersa + "s";
        if (this.numbersa == 0) {
          this.textsnums = "获取验证码";
          this.desidesa = false;
          this.numbersa = 60;
          clearInterval(this.timers);
        }
      }, 1000);
    },
    //提交新密码
    Submant(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            mobile: this.form.mobile,
            code: this.form.code,
            newpassword: this.$md5(this.form.newpassword),
            password: this.$md5(this.form.password),
          };
          forgetpass(data).then((res) => {
            //console.log(res)
            if (res.code == 200) {
              this.$message.success("重置密码成功");
              this.filg = false;
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
html,
body {
  overflow: hidden;
}
.login {
  width: 100%;
  height: 918px;
  overflow: hidden;
  background: url("../assets/img/ads43.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .adsads{
    cursor: pointer;
    position: absolute;
        width: 367px;
    height: 100px;
    // background: red;
    right: 175px;
    top: 40px;
  }
}
.login_center {
  max-width: 1250px;
  margin: 0 auto;
  margin-top: 245px;
  display: flex;
  justify-content: flex-end;
}
.login_card {
  width: 350px;
  /* height: 400px; */
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  box-shadow:1px 2px 14px rgb(0, 0, 0,  0.1);
}
.erma {
  width: 43px;
  height: 43px;
  position: absolute;
  right: 0;
  top: 0;
  background: url("../assets/img/erweima.png") no-repeat;
	cursor: pointer;
}
.actverma {
  width: 43px;
  height: 43px;
  position: absolute;
  right: 0;
  top: 0;
  background: url("../assets/img/zhanhao.png") no-repeat;
	cursor: pointer;
}
.lefterma {
  position: absolute;
  left: -73px;
  top: 12px;
  width: 73px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  background: #e5f7ff;
  border: 1px solid #a7e4ff;
  border-radius: 2px;
  font-size: 12px;
  color: #0075aa;
}
.sanjiao {
  position: absolute;
  right: -8px;
  top: 0;
  bottom: 0;
  height: 2px;
  margin: auto;
  border-top: 6px solid transparent;
  border-left: 8px solid #e5f7ff;
  border-bottom: 6px solid transparent;
}
.card_top {
  padding: 27px 25px 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
}
.colorsad {
  font-size: 18px;
  font-weight: bold;
  color: #0075aa;
}
.password {
  font-size: 18px;
  color: #535353;
  cursor: pointer;
}
.colores {
  font-size: 18px;
  font-weight: bold;
  color: #0075aa;
}
.twos {
  margin-left: 36px;
}
.card_center {
  padding: 30px 25px;
}
.wangji {
  margin-top: 18px;
  text-align: right;
  font-size: 14px;
  color: #0075aa;
  cursor: pointer;
}
.loginbtns {
  width: 300px;
  height: 40px;
  margin-top: 34px;
  text-align: center;
  line-height: 40px;
  background: #0075aa;
  border-radius: 2px;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}
.zuceto {
  margin-top: 35px;
  text-align: center;
  font-size: 14px;
  color: #0075aa;
  cursor: pointer;
}
.wxerwima {
  width: 185px;
  height: 185px;
  margin: 0 auto;
  // background: #1a8055;
  border: 1px solid #f2f2f2;
}
.wxmess {
  margin-top: 18px;
  text-align: center;
  font-size: 14px;
  color: #535353;
}
::v-deep .el-input-group__prepend {
  padding: 0;
  width: 40px;
  text-align: center;
}
.login_cards {
  width: 350px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  .login_top {
    height: 62px;
    border-bottom: 1px solid #f2f2f2;
    text-align: center;
    line-height: 62px;
    font-size: 18px;
    font-weight: bold;
    color: #0075aa;
  }
  .card_centers {
    padding: 30px 25px;
  }
  .wangjis {
    text-align: right;
    font-size: 14px;
    color: #0075aa;
    cursor: pointer;
  }
  .loginbtnsa {
    width: 300px;
    height: 40px;
    margin-top: 20px;
    text-align: center;
    line-height: 40px;
    background: #0075aa;
    border-radius: 2px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
  }
}
.weixindenglu {
  text-align: center;
  margin-top: 20px;
  .weixinerweima {
    width: 40px;
    height: 40px;
  }
}
.imgfliexs{
  cursor: pointer;
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>